import React, { useCallback } from "react";
import ReactCalendar from "react-calendar";

const Calendar = ({ value, onChange, highlightedDates = [] }) => {

  const tileClassName = useCallback(
    ({ date, view }) => {
      if (view === "month") {
        const isFound = highlightedDates.find(eventDate => {
          const eventtDate = new Date(eventDate).getTime();
          const currDate = new Date(date).getTime();

          const result = eventtDate === currDate;

          return result;
        });
        if (isFound) {
          return "event_day";
        }
      }
    },
    [highlightedDates]
  );

  return (
    <div className="calendar_container">
      <ReactCalendar
        onChange={onChange}
        value={value}
        tileClassName={tileClassName}
        locale="tr"
      />
      <p className="color_information">
        <span className="color" /> Etkinlikler
      </p>
    </div>
  );
};

export default Calendar;
