import React, { useCallback, useContext, useState } from "react";
import EventCard from "../../components/EventCard";
import SectionHeader from "../../components/Headers/SectionHeader";
import Calendar from "../../components/Calendar";
import { EventsDataContext, GeneralDataContext } from "../../context";
import { MdRemoveCircleOutline } from "react-icons/md";
import EventRegistrationForm from "../../components/EventRegistrationForm";

const Events = () => {
  const eventsData = useContext(EventsDataContext);
  const generalData = useContext(GeneralDataContext);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isFiltered, setIsFiltered] = useState(false);

  const changeDate = useCallback(date => {
    setSelectedDate(date);
    setIsFiltered(true);
  }, []);

  const clearFilter = () => {
    setSelectedDate(new Date());
    setIsFiltered(false);
  };

  const [isRegistrationFormOpen, setIsRegistrationFormOpen] = useState(false);
  // const [isRequestFormOpen, setIsRequestFormOpen] = useState(false);

  const openRegistrationForm = useCallback(() => {
    setIsRegistrationFormOpen(true);
  }, []);

  const closeRegistrationForm = useCallback(() => {
    setIsRegistrationFormOpen(false);
  }, []);

  // const openRequestForm = useCallback(() => {
  //   setIsRequestFormOpen(true);
  // }, []);

  // const closeRequestForm = useCallback(() => {
  //   setIsRequestFormOpen(false);
  // }, []);

  return (
    <section id="events">
      <EventRegistrationForm
        isOpen={isRegistrationFormOpen}
        closeForm={closeRegistrationForm}
      />
      {/* <EventRequestForm
        isOpen={isRequestFormOpen}
        closeForm={closeRequestForm}
      /> */}
      <SectionHeader text="Etkinlikler" />
      <Calendar
        highlightedDates={generalData?.eventDates}
        value={selectedDate}
        onChange={changeDate}
      />
      {/* <div className="request_container">
        <p className="request_container__text">
          {generalData?.ek_talep_yazisi}
        </p>
        <CtaButton text="Talepte Bulun" onClick={openRequestForm} />
      </div> */}
      {isFiltered && (
        <button
          type="button"
          className="clear_filter_button"
          onClick={clearFilter}
        >
          Filtreyi Temizle{" "}
          <span>
            <MdRemoveCircleOutline color="#FF2F00" />
          </span>
        </button>
      )}
      <div className="events__content_container">
        {eventsData?.edges.map(({ node }) => {
          const time = new Date(node.unformattedDate).setHours(0); // default to +3
          const selected = selectedDate.getTime();

          if (isFiltered && time !== selected) {
            return null;
          }

          return (
            <EventCard
              key={node.slug}
              slug={node.slug}
              featuredImage={
                node.etkinlik_resmi.childImageSharp.gatsbyImageData
              }
              relatedNewsSlug={node?.iliskili_haber?.slug}
              title={node.etkinlik_basligi}
              description={node.etkinlik_aciklamasi}
              date={node.etkinlik_tarihi}
              unformattedDate={node.unformattedDate}
              time={node.etkinlik_saati}
              openForm={openRegistrationForm}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Events;
